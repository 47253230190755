<template>
  <div class="company_card">
    <div class="job_card_inner">
      <div class="company" @click="goUrl('company')">
        <div class="com_photo">
          <img :src="item.logoUrl"/>
        </div>
        <div class="com_brief">
          <div class="com_name">
            {{item.fullName}}
          </div>
          <div class="com_txt">{{item.industryClassificationName}}&nbsp;&nbsp;{{item.companyScaleName}}</div>
        </div>
      </div>
      <div class="split_line"></div>

      <template v-for="(ele,index) in item.companyPostList" :key="ele.companyPostId">
        <div @click="goJobDetail(index)" class="job_title" v-if="index<3">
          <span>{{ele.postName}}</span>
          <div v-if="ele.salType === 1" class="job_salary">{{ele.salLow}}-{{ele.salHigh}}K &nbsp;{{ele.salMonths}}薪</div>
          <div v-else-if="ele.salType === 2" class="job_salary">{{userStore.SALARY_TALK}}</div>
          <div v-else-if="item.salType === 3" class="job_salary">{{item.salLow}}-{{item.salHigh}}/天</div>
        </div>
        <div class="job_brief" v-if="index<3">
          <span>{{ele.cityName}}</span> <span v-if="ele.workExperienceName">|</span> <span>{{ele.workExperienceName}}</span> <span v-if="ele.education>0">|</span> <span>{{this.userStore.getDegree(ele.education)}}</span>
        </div>
      </template>

<!--      <div class="job_title">-->
<!--        <span>云计算运维工程师</span> <div class="job_salary">10-14K</div>-->
<!--      </div>-->
<!--      <div class="job_brief">-->
<!--        <span>深圳</span>｜<span>1-3年</span>|<span>本科</span>-->
<!--      </div>-->
<!--      <div class="job_title">-->
<!--        <span>云计算运维工程师</span> <div class="job_salary">10-14K</div>-->
<!--      </div>-->
<!--      <div class="job_brief">-->
<!--        <span>深圳</span>｜<span>1-3年</span>|<span>本科</span>-->
<!--      </div>-->

      <div class="more_job" @click="goUrl('post')">查看更多职位 &gt;&gt;</div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useUserStore } from "@/store/user/user";
export default {
  name: "companyCard",
  props:{
    item:{
      type:Object,
      default(){
        return {
          // logoUrl:"",
          // fullName:"",
          // companyPostList:[],
          // industryClassificationName:"",
          // companyScaleName:""
        }
      }
    }
  },
  methods:{
    goJobDetail(index){
      this.$router.push({
        path:"/jobDetail",
        query:{
          id:this.item.companyPostList[index].companyPostId
        }
      });
    },
    goUrl(act){
      if(act === "company"){
        this.$router.push({
          path:"/companyDetail",
          query:{
            id:this.item.companyId
          }
        });
      }else if(act === "post"){
        this.$router.push({
          path:"/companyDetail",
          query:{
            id:this.item.companyId
          }
        });
      }

    }
  },
  computed:{
    ...mapStores(useUserStore)
  }
}
</script>

<style scoped lang="less">
@import "../assets/css/variable";
.company_card{
  width:386.5px;
  height:430px;
  overflow: hidden;
  background-color:#fff;
  .job_card_inner{
    margin:25px;
    position:relative;
  }
  .job_title{
    font-size: 16px;
    line-height: 40px;
    color: #1a1a1a;
    display:flex;
    justify-content: space-between;
    cursor:pointer;
    .job_salary{
      color:#ed6e2d;
    }
  }
  .split_line{
    border-top:1px solid #f0f3f7;
    width:100%;
    margin:20px auto;
  }
  .job_brief{
    font-size: 14px;
    color: #999999;
    margin-bottom:25px;
  }
  .more_job{
    font-size: 16px;
    color: @blue_color;
    cursor:pointer;
  }
  .company{
    display:flex;
    cursor:pointer;
    .com_brief{
      margin-left:10px;
    }
    .com_name{
      font-size: 14px;
      color: #666666;
    }
    .com_txt{
      font-size: 12px;
      color: #999999;
      margin-top:3px;
    }
    .com_photo{
      width:50px;
      height:40px;
      img{
        width:100%;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
</style>
