<template>
  <div class="login_box">
<!--    <div class="login_title">登录/注册</div>-->
    <el-radio-group v-model="userType">
      <el-radio-button :label="userTypeJob"/>
      <el-radio-button :label="userTypeCompany"/>
    </el-radio-group>
    <div class="input_wrapper">
      <el-select v-model="tel_pre" placeholder="Select">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
      <div class="split_line"></div>
      <el-input v-model="phone" @focus="showRightPhoneTip=false" placeholder="手机" style="flex:1;text-align: left;border:none;background-color: transparent;"/>
      <div class="warning" v-show="showRightPhoneTip">
        <img src="../assets/img/icon_warning.png"/>请输入正确的手机号
      </div>
    </div>
    <div class="input_wrapper" v-show="userType === userTypeCompany" >
      <el-input v-model="password" type="password" @focus="showRightPasswordTip=false" placeholder="请输入密码" style="flex:1;text-align: left;border:none;background-color: transparent;"/>
      <div class="warning" v-show="showRightPasswordTip">
        <img src="../assets/img/icon_warning.png"/>请输入密码
      </div>
    </div>
    <div class="input_wrapper" v-show="userType === userTypeJob">
      <el-input v-model="msg" @focus="showRightCodeTip=false" placeholder="短信验证码" style="flex:1;text-align: left;border:none;background-color: transparent;"/>
      <div class="split_line"></div>
      <div class="get_msg" @click="getMsgCode" v-show="!showCountdown">获取验证码</div>
      <div class="get_msg_again" v-show="showCountdown">重新获取({{countDownTotal}}S)</div>
      <div class="warning" v-show="showRightCodeTip">
        <img src="../assets/img/icon_warning.png"/>短信验证码验证错误或失效
      </div>
    </div>
    <div class="login" @click="login">登录</div>
    <div class="agree">
      <el-checkbox id="agree" @change="showAgreeTip=false" v-model="agree"/>
      <div style="margin-left:20px;">
        <span>同意嵌入式</span>
        <a href="javascript:void(0)">《嵌入式用户服务协议》</a><span>及</span>
        <a href="javascript:void(0)">《个人信息保护政策》</a>
      </div>
      <div class="warning" v-show="showAgreeTip">
        <img src="../assets/img/icon_warning.png"/> 请阅读并勾选同意嵌入式相关服务协议
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useUserStore } from "@/store/user/user";
import { useCompanyStore } from "@/store/company/company";
import { checkPhone } from "@/util/util";
import CONSTANTS from "@/api/user/constant";
export default {
  name: "loginBox",
  data(){
    return {
      tel_pre:"86",
      phone:"",
      password:"",
      msg:"",
      agree:false,
      showRightPhoneTip:false,
      showRightCodeTip:false,
      showCountdown:false,
      showRightPasswordTip:false,
      countDownTotal:60,
      showAgreeTip:false,
      userType:"我要找工作",
      userTypeJob:"我要找工作",
      userTypeCompany:"我要招聘",
      options:[ { label: "+86", value: "86" },
        { label: "+852", value: "852" },
        { label: "+853", value: "853" },
        { label: "+886", value: "886" },
        { label: "+65", value: "65" },
        { label: "+60", value: "60" },
        { label: "+672", value: "672" },
        { label: "+1", value: "1" },
        { label: "+39", value: "39" },
        { label: "+33", value: "33" },
        { label: "+81", value: "81" },],
    }
  },
  methods:{
    countDown(){
      this.countDownTotal = this.countDownTotal - 1;
      if(this.countDownTotal<=0){
        this.showCountdown = false;
        return ;
      }
      setTimeout(() => {
        this.countDown();
      },1000)
    },
    userLogin(){
      this.userStore.login({
        mobile:this.phone,
        captcha:this.msg
      }).then(res => {
        if(res.code === 0){
          localStorage.setItem("JOB_USER_TOKEN",res.data.token);
          localStorage.setItem("JOB_USER_REFRESH_TOKEN",res.data.refreshToken);
          localStorage.removeItem("JOB_COMPANY_TOKEN");
          localStorage.removeItem("JOB_COMPANY_REFRESH_TOKEN");
          this.userStore.getUserInfo();
        }else{
          this.userStore.logout();
        }
      }).catch((err) => {
        console.log("xxx",err);
          this.userStore.logout();
      });
    },
    companyLogin(){
      this.companyStore.login({
        mobile:this.phone,
        password:this.password
      }).then(res => {
        if(res.code === 0){
          localStorage.setItem("JOB_COMPANY_TOKEN",res.data.token);
          localStorage.setItem("JOB_COMPANY_REFRESH_TOKEN",res.data.refreshToken);
          // localStorage.removeItem("JOB_USER_TOKEN");
          // localStorage.removeItem("JOB_USER_REFRESH_TOKEN");
          this.userStore.logout();
          // this.companyStore.getCompanyAccount();
          window.location.href=CONSTANTS.COMPANY_URL+'#/person';
          // this.$router.push({
          //   path:CONSTANT.ROOT_URL
          // });
        }else{
          this.$message({
            message:res.msg,
            type:"error"
          });
          localStorage.removeItem("JOB_COMPANY_TOKEN");
          localStorage.removeItem("JOB_COMPANY_REFRESH_TOKEN");
        }
      });
    },
    login(){
      if(!checkPhone(this.phone)){
        this.showRightPhoneTip=true;
        return;
      }
      if(this.userType === this.userTypeJob){
        if(this.msg === "" || isNaN(this.msg)){
          this.showRightCodeTip=true;
          return ;
        }
      }
      else {
        if(this.password === ""){
          this.showRightPasswordTip = true;
          return ;
        }
      }

      if(!this.agree){
        this.showAgreeTip = true;
        return ;
      }
      if(this.userType === this.userTypeJob){
        this.userLogin();
      }else if(this.userType === this.userTypeCompany){
        this.companyLogin();
      }
    },
    getMsgCode() {
      if(!checkPhone(this.phone)){
        this.showRightPhoneTip = true;
        return ;
      }
      this.userStore.getMsgCode({
        areaNum:this.tel_pre,
        mobile:this.phone
      }).then(res => {
        if(res.code === 0){
          this.$message({
            type:"success",
            message:"验证码已发送"
          });
          this.showCountdown = true;
        }else{
          this.$message.error(res.msg);
        }
      });
    }
  },
  watch:{
    showCountdown(v){
      if(v){
        this.countDownTotal = 60;
        setTimeout(()=>{
          this.countDown();
        },1000)
      }
    }
  },
  computed:{
    ...mapStores(useUserStore,useCompanyStore)
  },
  mounted(){
    // this.$message({
    //   type:"error",
    //   message:"hfie"
    // });
    if(this.$route.query.m!==undefined && this.$route.query.m === 'r'){
      this.userType = this.userTypeCompany;
    }
  }
}
</script>

<style lang="less">
@import "../assets/css/variable.less";
  .login_box{
    overflow: hidden;
    background-color:#fff;
    border-radius:10px;
    width:320px;
    padding-bottom:10px;
    .el-select{
      width:80px;
      .el-input__inner{
        border:none;
        background-color:transparent;
        text-align: center;
      }
    }
    .el-radio-group{
      margin:15px 25px;
    }
    .el-radio-button__original-radio:checked+.el-radio-button__inner{
      background-color:#00b0b1;
    }
    .el-radio-button__original-radio:checked+.el-radio-button__inner:hover{
      color:#fff;
    }
    .el-radio-button__inner:hover{
      color:#00b0b1;
    }
    .el-radio-button__inner{
      width:135px;height:40px;line-height:22px;
    }
    //.el-checkbox__input.is-checked .el-checkbox__inner{
    //  background-color:@blue_color;
    //  border-color:@blue_color;
    //}
    .warning{
      position:absolute;
      bottom:-15px;
      height:15px;
      display:flex;
      align-items: center;
      font-size: 12px;
      color: #ff4b49;
      margin-left:22px;
      img{
        margin-right:5px;
        width:11px;height:11px;
      }
    }
    .login_title{
      height:60px;
      line-height:60px;
      font-size: 18px;
      text-align: center;
      color: #1a1a1a;
    }
    .input_wrapper{
      border-radius: 20px;
      width:270px;
      margin:0 auto 15px;
      height:40px;
      line-height:40px;
      background-color: #f6f6f8;
      display:flex;
      align-items: center;
      position:relative;
      .el-input.is-focus .el-input__wrapper,
      .el-input__wrapper{
        box-shadow: none!important;
        background-color:transparent;
      }
      .split_line{
        height:15px;
        border-left:1px solid #eaeaeb;
      }
      .get_msg,.get_msg_again{
        font-size: 13px;
        line-height: 40px;
        color: @blue_color;
        width:110px;cursor:pointer;
        text-align: center;
      }
      .get_msg_again{
        color:#ccc;
      }
    }
    .login{
      width:270px;
      text-align: center;
      font-size: 16px;
      cursor:pointer;
      color: #fff;
      height:40px;line-height:40px;border-radius: 20px;
      background-color: @blue_color;
      margin:0 auto 15px;
    }
    .agree{
      width:270px;
      font-size: 12px;
      color: #cccccc;
      margin: 0 auto 15px;
      position:relative;
      .el-checkbox{
        height:14px;
        position:absolute;
        left:0;top:2px;
      }
      a{
        text-decoration: none;
        color:#00b0b1;
      }
    }
  }
</style>
