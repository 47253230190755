<template>
<div class="job_card">
  <div class="job_card_inner">
    <div class="job_title" @click="goJobDetail">
      <div class="job_name">{{item.postName}}</div>
      <div v-if="item.salType === 1" class="job_salary">{{item.salLow}}-{{item.salHigh}}K</div>
      <div v-else-if="item.salType === 2" class="job_salary">{{userStore.SALARY_TALK}}</div>
      <div v-else-if="item.salType === 3" class="job_salary">{{item.salLow}}-{{item.salHigh}}/天</div>
    </div>
    <div class="job_brief">
      <span>{{item.cityName}}</span> | <span>{{item.workExperienceName}}</span> | <span>{{userStore.getDegree(item.education)}}</span>
    </div>
    <div class="split_line"></div>
    <div class="company" @click="goCompany">
      <div class="com_photo">
        <img :src="item.logoUrl"/>
      </div>
      <div class="com_brief">
        <div class="com_name">
          {{item.fullName}}
        </div>
        <div class="com_txt">{{item.industryClassificationName}}  {{item.companyScaleName}}</div>
      </div>
    </div>
  </div>

</div>
</template>

<script>

import {mapStores} from "pinia";
import {useUserStore} from "@/store/user/user";

export default {
  name: "jobCard",
  props:["item"],
  methods:{
    goJobDetail(){
      this.$router.push({
        path:"/jobDetail",
        query:{
          id:this.item.companyPostId
        }
      });
    },
    goCompany(){
      this.$router.push({
        path:"/companyDetail",
        query:{
          id:this.item.companyId
        }
      });
    }
  },
  computed:{
    ...mapStores(useUserStore)
  }
}
</script>

<style scoped lang="less">
@import "../assets/css/variable";
  .job_card{
    width:386.5px;
    height:220px;
    overflow: hidden;
    background-color:#fff;
    .job_card_inner{
      margin:25px;
      position:relative;
    }
    .job_title{
      font-size: 18px;
      line-height: 60px;
      color: #1a1a1a;
      display:flex;
      justify-content: space-between;
      cursor:pointer;
      .job_salary{
        color:#ed6e2d;
      }
    }
    .job_name{
      flex:1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .split_line{
      border-top:1px solid #f0f3f7;
      width:100%;
      margin:25px auto;
    }
    .job_brief{
      font-size: 14px;
      color: #999999;
    }
    .company{
      display:flex;
      cursor:pointer;
      .com_brief{
        margin-left:10px;
      }
      .com_name{
        font-size: 14px;
        color: #666666;
      }
      .com_txt{
        font-size: 12px;
        color: #999999;
        margin-top:3px;
      }
      .com_photo{
        width:50px;
        img{
          width:100%;
        }
      }
    }
  }
</style>
