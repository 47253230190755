<template>
  <div class="hot_title">
    <div class="title">{{title}}</div>
    <div class="keywords">
      <div class="kw"
           :key="index"
           :class="{kw_cur:selectedIndex === index}"
           v-for="(item,index) in list" @click="selectKeyword(index)">
        {{item.name}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    title:{
      type:String,
      value:""
    },
    keywords:{
      type:Array,
      value:[]
    }
  },
  data(){
    return {
      selectedIndex:-1,
      list:this.keywords
    };
  },
  methods:{
    selectKeyword(index){
      if(this.selectedIndex === index){
        return ;
      }
      this.selectedIndex = index;

      this.$emit("sub_event",{act:"select_keyword",id:this.list[index].id});
    }
  },
  watch:{
    keywords:{
      deep:true,
      handler(v){
        this.list = v;
        this.selectKeyword(0)
      }
    }
  },
  name: "hotTitle"
}
</script>

<style lang="less">
 @import "../assets/css/variable";
 .hot_title{
   width:100%;
   .title{
     font-size: 34px;
     height:60px;
     line-height: 60px;
     color: @font_color;
     font-weight: bold;
     text-align: center;
   }
   .keywords{
     display:flex;
     margin-top:20px;
     .kw{
       font-size: 18px;
       color: @font_color;
       margin-right:70px;
       cursor:pointer;
     }
     .kw_cur{
       color:@blue_color;
     }
   }
 }
</style>
