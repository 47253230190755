<template>
<div class="page_home">
  <div class="banner_wrapper">
    <div class="content">
      <img class="banner_pic" @click="clickBanner('index')" :src="topBanner.imageUrl"/>
      <div style="position:absolute;top:10px;right:10px;" v-if="!userStore.isLogin && !companyStore.isLogin">
        <login-box></login-box>
      </div>
    </div>
  </div>

  <div class="search_bar" :class="{search_focus:searchFocus}" @click.stop="focusSearch">
    <el-select v-model="searchType" placeholder="搜索类型" style="width: 100px;margin-left: 10px;">
      <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.label"
          :value="item.id"
      />
    </el-select>
<!--    <div class="btn_show_type" @click="funcShowCatPanel">{{curJobCat.name}} &nbsp;&nbsp;<img class="triangle" :class="{triangle_up:showCatPanel}" src="../assets/img/icon_triangle.png"/></div>-->
    <el-input v-model="keyword" placeholder="搜索职位/公司" @focus="searchFocus=true" style="flex:1;text-align: left;border:none;background-color: transparent;"/>
    <el-button class="search_btn" @click="indexSearch">搜索</el-button>
<!--    <div style="position:absolute;left:10px;top:70px;z-index:1;">-->
<!--      <cat-box v-show="showCatPanel" @sub_event="subEvent"></cat-box>-->
<!--    </div>-->
  </div>
<!--  <div class="hot_search">-->
<!--    热搜：<span v-for="item in hotPositionKeywords" :key="item.id" @click="hotKeywordClick(item)">{{item.name}}</span>-->
<!--  </div>-->
  <hot-search @sub_event="subEvent"></hot-search>
  <div class="cat_wrapper content">
    <div class="cat_list">
      <cat-box-big></cat-box-big>
    </div>
    <div class="h_cat_middle" @click="clickBanner('middle')">
      <img :src="middleBanner.imageUrl"/>
    </div>
    <div class="h_cat_right">
      <div class="right_top" @click="clickBanner('middle_top')">
        <img class="r_top" :src="middleTopBanner.imageUrl"/>
      </div>
      <div class="right_bottom" @click="clickBanner('middle_bottom')">
        <img class="r_bottom" :src="middleBottomBanner.imageUrl"/>
      </div>
    </div>
  </div>

  <div class="content" style="margin-top:50px;margin-bottom:30px;">
    <hot-title title="热门职位" @sub_event="selectHotPosition" :keywords="hotPosition"></hot-title>
  </div>

  <div class="job_list content">
    <job-card
      v-for="item in hotJobList"
      :key="item.companyPostId"
      :item="item"
    ></job-card>
  </div>

  <div class="look_more" @click="goJobList">查看更多</div>

  <div class="content" style="margin-top:50px;margin-bottom:30px;">
    <hot-title title="热门企业" :keywords="hotCompanyKeyWords"></hot-title>
  </div>
  <div class="company_list content">
    <company-card
        v-for="item in hotCompanyList"
        :item="item"
        :key="item.companyId"
    ></company-card>
  </div>
  <div class="look_more" @click="goCompanyList">查看更多</div>
</div>
</template>

<script>
import loginBox from "@/components/loginBox";
import CatBox from "@/components/catBox";
import catBoxBig from "@/components/catBoxBig";
import hotTitle from "@/components/hotTitle";
import JobCard from "@/components/jobCard";
import CompanyCard from "@/components/companyCard";
import { mapStores } from 'pinia';
import { useUserStore } from "@/store/user/user";
import { useCompanyStore } from "@/store/company/company";
import {indexHotPosition,indexHotPostList,indexHotCompany} from "@/api/user/user";

import middleImg from "../assets/img/test_cat_middle.png";
import middleTopImg from "../assets/img/test_cat_top.png";
import middleBottomImg from "../assets/img/test_cat_bottom.png";
import HotSearch from "@/components/hotSearch";

export default {
  name: "home",
  data(){
    return {
      searchType:1,
      options:[{
        id:1,
        label:"按公司搜"
      },{
        id:2,
        label:"按职位搜"
      }],
      showCatPanel:false,
      searchFocus:false,
      keyword:"",
      curJobCat:{
        id:0,
        name:"职业类型"
      },
      topBanner:{
        id:"",
        imageUrl:"",
        jumpLink:""
      },
      middleBanner:{
        id:"",
        imageUrl:middleImg,
        jumpLink:""
      },
      middleTopBanner:{
        id:"",
        imageUrl:middleTopImg,
        jumpLink:""
      },
      middleBottomBanner:{
        id:"",
        imageUrl:middleBottomImg,
        jumpLink:""
      },
      hotPosition:[],
      hotJobList:[],
      hotCompanyList:[],
      hotCompanyKeyWords:[
          // {name:'小米',id:0},{name:"格里",id:1}
          ],
      // hotPositionKeywords:[],
      // options:[
      //   {
      //     label:"职业类型",
      //     value:"职业类型"
      //   }
      // ]
    }
  },
  methods:{
    // hotKeywordClick(item){
    //   this.keyword = item.name;
    //   this.indexSearch();
    // },
    indexSearch(){
      if(this.keyword.trim() === ""){
        this.$message({
          type:"warning",
          message:"请输入关键词"
        });
        return ;
      }
      if(this.searchType === 1){
        this.$router.push({
          path:"/company_list",
          query:{
            keyword:this.keyword
          }
        });
      }else if(this.searchType === 2){
        this.$router.push({
          path:"/job",
          query:{
            keyword:this.keyword
          }
        });
      }
    },
    selectHotPosition(param){
        this.getHotJobList(param.id)
    },
    getHotJobList(id=""){
      indexHotPostList({
        pageNum:1,
        pageSize:6,
        postClassificationId:id,
      }).then(res => {
        console.log(res);
        if(res.code === 0){
          this.hotJobList = res.data.rows;
        }
      });
    },
    clickBanner(act){
      if(act === "index"){
        if(this.topBanner.jumpLink!==''){
          window.open(this.topBanner.jumpLink);
        }
      }else if(act === "middle"){
        if(this.middleBanner.jumpLink !== ''){
          window.open(this.middleBanner.jumpLink);
        }
      }else if(act === "middle_top"){
        if(this.middleTopBanner.jumpLink !== ''){
          window.open(this.middleTopBanner.jumpLink);
        }
      }else if(act === "middle_bottom"){
        if(this.middleBottomBanner.jumpLink !== ''){
          window.open(this.middleBottomBanner.jumpLink);
        }
      }

      window.opener = null;
    },
    // funcShowCatPanel(){
    //   this.showCatPanel=!this.showCatPanel;
    //   if(this.showCatPanel){
    //     let that = this;
    //     let blur=function (){
    //       that.showCatPanel = false;
    //       document.removeEventListener("click",blur);
    //     }
    //     document.addEventListener("click",blur);
    //   }
    // },
    focusSearch(){
      let that = this;
      this.searchFocus=true;
      let blur=function (){
        that.searchFocus = false;
        document.removeEventListener("click",blur);
      };
      document.addEventListener("click",blur);
    },
    goCompanyList(){
      this.$router.push({
        path:"/company_list"
      });
    },
    goJobList(){
      this.$router.push({
        path:"/job"
      });
    },
    subEvent(param){
      if(param.act === "select_cat"){
        this.curJobCat = param.info;
      }else if(param.act === "set_hot_search"){
        this.keyword = param.keyword;
        this.indexSearch();
      }
    }
  },
  mounted() {
    this.userStore.getBannerList().then(list => {
      for(let i in list){
        if(list[i].type === 1){
          this.topBanner = list[i];
        }else if(list[i].type === 2){
          this.middleBanner = list[i];
        }else if(list[i].type === 3){
          this.middleTopBanner = list[i];
        }else if(list[i].type === 4){
          this.middleBottomBanner = list[i];
        }
      }
    });

    indexHotPosition().then(res => {
      if(res.code === 0){
        this.hotPosition = res.data;
      }
    });
    // indexHotPositionKeywords().then(res => {
    //   if(res.code === 0){
    //     this.hotPositionKeywords = res.data;
    //   }
    // });
    indexHotCompany({
      pageNum:1,
      pageSize:10
    }).then(res => {
      console.log(res);
      this.hotCompanyList = res.data.rows;
    });
    // this.getHotJobList();
  },
  components:{
    HotSearch,
    CompanyCard,
    JobCard,
    CatBox,
    catBoxBig,
    loginBox,
    hotTitle,
  },
  computed:{
    ...mapStores(useUserStore,useCompanyStore)
  }
}
</script>

<style lang="less">
@import "../assets/css/variable.less";
  .page_home{
    .cat_wrapper{
      display:flex;
      height:360px;
      margin:50px auto 0;
      justify-content: space-between;
      .h_cat_middle{
        width:530px;
        cursor:pointer;
        img{
          width:100%;
          height:100%;
        }
      }
      .h_cat_right{
        img{
          width:100%;
          height:100%;
        }
        width:300px;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        .right_top{
          height:177px;
          cursor:pointer;
        }
        .right_bottom{
          height:177px;
          cursor:pointer;
        }
      }
      .cat_list{
        width:360px;
        height:360px;
        background-color: #fff;
      }
    }

    .hot_search{
      font-size: 14px;
      color: #999999;
      width:1020px;
      margin:0 auto;
      text-indent:25px;
      span{
        cursor:pointer;
        margin-right:10px;
      }
    }
    .search_bar{
      box-sizing: border-box;
      border:1px solid #fff;
      position:relative;
      background-color:#fff;
      width:1020px;
      height:50px;
      display:flex;
      align-items: center;
      border-radius:25px;
      margin:50px auto 10px;
      .search_btn{
        width:80px;height:40px;border-radius:20px;background-color:@blue_color;
        margin-right:8px;color:#fff;
      }
      .btn_show_type{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 20px;
        cursor: pointer;
        color: #1a1a1a;
      }
      //.el-select{
      //  width:120px;
      //  .el-input__inner{
      //    border:none;
      //    background-color:transparent;
      //    text-align: center;
      //  }
      //}
    }
    .search_focus{
      border:1px solid @blue_color;
    }
    .banner_wrapper{
      background-color:rgba(102, 110, 232, 1);
      .content{
        position:relative;
        height:310px;
        .banner_pic{
          height:100%;
          cursor:pointer;
        }
      }
    }
    .look_more{
      width: 160px;
      height: 44px;
      line-height:44px;
      border-radius: 22px;
      background-color: #00b0b1;
      cursor:pointer;
      margin:30px auto 0;
      color:#fff;
      font-size: 16px;
      text-align: center;
    }
    .company_list{
      display:flex;
      flex-wrap: wrap;
      .company_card{
        margin-right:20px;
        margin-bottom:20px;
        &:nth-child(3n){
          margin-right:0;
        }
      }
    }
    .job_list{
      display:flex;
      flex-wrap: wrap;
      .job_card{
        margin-right:20px;
        margin-bottom:20px;
        &:nth-child(3n){
          margin-right:0;
        }
      }
    }
  }
</style>
