<template>
  <div class="cat_box_big" @mouseleave="this.showSubCat=false">
    <div class="cat_left">
      <div class="cat_left_item"
           v-for="item in catListShow"
           :key="item.id"
           @mouseover="funcShowSubCat(item.id)">
        <div class="item_title">{{item.name}}</div>
        <div class="item_sub_title" v-for="ele in item.children" :key="ele.id" @click="goJob(ele.id)">{{ele.name}}</div>
        <div class="triangle_point"></div>
      </div>

<!--      <div class="cat_left_item">-->
<!--        <div class="item_title" @click="goJob(-1)">全部职位</div>-->
<!--      </div>-->
      <div class="bottom_page" @mouseover="this.showSubCat=false">
        <div class="pager">
          <span>{{pageNum}} / {{totalPage}}</span>
          <div>
            <el-pagination
                :page-size="pageSize"
                v-model:current-page="pageNum"
                layout="prev, pager, next"
                :total="total"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="cat_right" :class="{show_cat_right:showSubCat}">
      <div class="cat_title">{{selectedSubCatList.name}}</div>
      <div class="cat_content">
        <div class="cat_item" v-for="item in selectedSubCatList.children" @click="goJob(item.id)">{{item.name}}</div>
      </div>
<!--      <div class="cat_title">研发</div>-->
<!--      <div class="cat_content">-->
<!--        <div class="cat_item">材料工程师</div>-->
<!--        <div class="cat_item">材料工程师</div>-->
<!--        <div class="cat_item">材料工程师</div>-->
<!--        <div class="cat_item">材料工程师</div>-->
<!--        <div class="cat_item">材料工程师</div>-->
<!--        <div class="cat_item cat_item_cur">材料工程师</div>-->
<!--        <div class="cat_item">材料工程师</div>-->
<!--        <div class="cat_item">材料工程师</div>-->
<!--        <div class="cat_item">材料工程师</div>-->
<!--        <div class="cat_item">材料工程师</div>-->
<!--        <div class="cat_item">材料工程师</div>-->
<!--        <div class="cat_item">材料工程师</div>-->
<!--      </div>-->
<!-- -->
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useUserStore} from "@/store/user/user";
import {jsCopy} from "@/util/util";

export default {
  name: "catBox",
  data(){
    return {
      showSubCat:false,
      Cat2deep:[],
      catListTrans:{},
      selectedSubCatList:{name:"",children:[]},
      lineCount:20,

      catListShow:[],
      pageNum:1,
      pageSize:6,
      totalPage:1,
      total:0
    }
  },
  methods:{
    funcShowSubCat(id){
      // this.showSubCat=true;
      // let that = this;
      // let blur = function (){
      //   that.showSubCat = false;
      // }
      this.showSubCat = true;
      this.selectedSubCatList = this.catListTrans[id];
    },
    goJob(id){
      if(id === -1){
        this.$router.push({
          path:"/job"
        });
      }else{
        this.$router.push({
          path:"/job",
          query:{
            pcid:id
          }
        });
      }
    },
    getSubCat(parentId,allCat,c=0){
      let list = [];
      for(let i in allCat){
        if(allCat[i].parentId === parentId){
          let info = allCat[i];
          info.children = [];
          c += info.name.length;
          if(c<this.lineCount){
            list.push(info);
          }
        }
      }
      return list;
    },
    getEntireSubCat(parentId,allCat){
      let list = [];
      for(let i in allCat){
        if(allCat[i].parentId === parentId){
          let info = allCat[i];
          info.children = [];
          list.push(info);
        }
      }
      return list;
    },
    getList(){
      let start = (this.pageNum-1)*this.pageSize;
      let end = start + this.pageSize;
      this.catListShow = jsCopy(this.Cat2deep.slice(start,end));
    },
    sortCat(){
      if(this.userStore.positionList.length === 0){
        return ;
      }
      let positionList = jsCopy(this.userStore.positionList);
      this.Cat2deep = this.getSubCat(0,positionList,0);

      for(let i in this.Cat2deep){
        this.Cat2deep[i].children = this.getSubCat(this.Cat2deep[i].id,positionList,this.Cat2deep[i].name.length);
        this.catListTrans[this.Cat2deep[i].id] = {
          name:this.Cat2deep[i].name,
          children:this.getEntireSubCat(this.Cat2deep[i].id,positionList)
        }
      }
      this.total = this.Cat2deep.length;
      this.totalPage = Math.ceil(this.total/this.pageSize);
      this.getList();

    }
  },
  watch:{
    "userStore.positionList.length":{
      handler(v){
        this.sortCat();
      }
    },
    pageNum(v){
      this.getList();
    }
  },
  mounted(){
    // this.funcShowSubCat(this.userStore.positionList[0]);
    this.sortCat()
  },
  computed:{
    ...mapStores(useUserStore)
  },
}
</script>

<style lang="less">
@import url(../assets/css/variable);
.cat_box_big{
  position:relative;
  height:100%;
  .cat_left{
    width:100%;
    overflow: hidden;
    background-color: #fff;
    .cat_left_item{
      height:45px;
      line-height:45px;
      font-size: 14px;
      color: #1a1a1a;
      display:flex;
      align-items: center;
      cursor:pointer;
      &:first-child{
        margin-top:20px;
      }
      .item_title{
        font-size: 16px;
        color: #1a1a1a;
        margin-left:30px;
      }
      .item_sub_title{
        font-size: 15px;
        color: #999999;
        margin-left:25px;
      }
      .triangle_point{
        width:8px;height:9px;
        margin-left:10px;
        position:absolute;
        right:15px;
        background: url(../assets/img/triangle_gray_right.png) no-repeat center center / auto 100%;
      }
    }
    .cat_left_item:hover,
    .cat_left_item_cur{
      background-color: #f5f5f5;
      .item_title{
        color:@blue_color
      }
      //.triangle_point{
      //  width:8px;height:9px;
      //  background: url(../assets/img/triangle_blue_right.png) no-repeat center / auto 100%;
      //  transform: initial;
      //}
    }
  }
  .cat_right{
    position:absolute;
    transition: width .2s;
    top:0;
    left:360px;
    border-left:5px solid #f3f2f0;
    width:0;
    background-color:#fff;
    height:100%;
    overflow-x: hidden;
    overflow-y:auto;
    .cat_title{
      margin-left:15px;
      font-size: 14px;
      margin-top:10px;
      line-height: 35px;
      height:35px;
      color: #1a1a1a;
      font-weight: bold;
    }
    .cat_content{
      display:flex;
      flex-wrap: wrap;
      .cat_item{
        cursor:pointer;
        height:25px;line-height:25px;font-size:14px;
        margin-left:15px;color:#999999;
        &:hover{
          color:@blue_color;
        }
      }
      .cat_item_cur{
        color:@blue_color;
      }
    }
  }
  .show_cat_right{
    width:530px
  }
  .bottom_page{
    position: absolute;
    border-top:1px dashed #dcdcdc;
    bottom: 0;
    left:0;
    right:0;
    padding:10px 0;
    .pager{
      color:@blue_color;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 30px;
      margin-right: 30px;
    }
    .el-pager{
      display:none;
    }
  }
}
</style>
